
export default {
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 1120,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 760,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  


  DataItems: [
    {
      Hull: [
        {BLK: 'NP1UH', x1: 591.7, y1: 432.2, x2: 746.7, y2: 361.5,},
        {BLK: 'NP4UH', x1: 443.6, y1: 494.6, x2: 143.6, y2: 494.6,},
        {BLK: 'C10UH', x1: 623.5, y1: 335.1, x2: 778.5, y2:  82.4,},
        {BLK: 'C40UH', x1: 443.6, y1: 484.2, x2: 143.6, y2: 238.8,},
        {BLK: 'NP2UH', x1: 752.4, y1: 532.0, x2: 993.0, y2: 694.7,},
        {BLK: 'C20UH', x1: 861.6, y1: 446.6, x2: 1102.2,y2: 412.8,},
        {BLK: 'NP3UH', x1: 538.0, y1: 627.5, x2: 272.3, y2: 852.2,},
        {BLK: 'C30UH', x1: 678.2, y1: 584.5, x2: 412.5, y2: 564.2,},
      ]
    },
  ],

  Queries: {

    SQL1: [
      { SN: '1',  BLK: 'CF111', AREA: 'Hull', ZONE: 'UCFC',  KEY: 'Y', TITLE: 'UCF CENTER',                 X1: 358.51, Y1: 571.07, X2: 358.51, Y2: 571.07, X3: 358.51, Y3: 1,      X4: 359.09, Y4: 1.03, },
{ SN: '2',  BLK: 'EC112', AREA: 'Hull', ZONE: 'EN',    KEY: 'Y', TITLE: 'Lower Column (East-North)',  X1: 298.11, Y1: 682.93, X2: 107.86, Y2: 657.6,  X3: 107.86, Y3: 597.8,  X4: 108.44, Y4: 598.3, },
{ SN: '3',  BLK: 'EC1G2', AREA: 'Hull', ZONE: 'EN',    KEY: 'Y', TITLE: 'Upper Column (East-North)',  X1: 298.11, Y1: 622.07, X2: 107.86, Y2: 596.6,  X3: 107.86, Y3: 406.8,  X4: 108.44, Y4: 407.08, },
{ SN: '4',  BLK: 'EC1K2', AREA: 'Hull', ZONE: 'EN',    KEY: 'Y', TITLE: 'Middle Column (East-North)', X1: 298.11, Y1: 642.34, X2: 107.86, Y2: 616.63, X3: 108.11, Y3: 496.25, X4: 108.44, Y4: 496.83, },
{ SN: '5',  BLK: 'EC212', AREA: 'Hull', ZONE: 'ES',    KEY: 'Y', TITLE: 'Lower Column (East-South)',  X1: 433.02, Y1: 601.55, X2: 473.9,  Y2: 442.92, X3: 473.9,  Y3: 374.39, X4: 474.48, Y4: 374.97, },
{ SN: '6',  BLK: 'EC2G2', AREA: 'Hull', ZONE: 'ES',    KEY: 'Y', TITLE: 'Upper Column (East-South)',  X1: 439.28, Y1: 538.25, X2: 480.18, Y2: 379.61, X3: 480.55, Y3: 191.42, X4: 480.74, Y4: 192, },
{ SN: '7',  BLK: 'EC2K2', AREA: 'Hull', ZONE: 'ES',    KEY: 'Y', TITLE: 'Middle Column (East-South)', X1: 437.92, Y1: 561.01, X2: 478.8,  Y2: 401.45, X3: 478.8,  Y3: 277.38, X4: 479.39, Y4: 277.93, },
{ SN: '8',  BLK: 'EC3A',  AREA: 'Hull', ZONE: 'EN',    KEY: '',  TITLE: '',                           X1: 313.92, Y1: 714.63, X2: 123.75, Y2: 687.37, X3: 123.75, Y3: 628.66, X4: 93.84,  Y4: 646.57, },
{ SN: '9',  BLK: 'EC4A',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: '',                           X1: 483.84, Y1: 603.78, X2: 525.04, Y2: 445.14, X3: 525.29, Y3: 377.11, X4: 546.3,  Y4: 364.3, },
{ SN: '10', BLK: 'ECP1',  AREA: 'Hull', ZONE: 'EN',    KEY: '',  TITLE: 'Access P/F (East-North)',    X1: 352.68, Y1: 622.9,  X2: 162.71, Y2: 597.14, X3: 162.71, Y3: 407.33, X4: 178.44, Y4: 398.24, },
{ SN: '11', BLK: 'ECP3',  AREA: 'Hull', ZONE: 'EN',    KEY: '',  TITLE: 'Access P/F (East-North)',    X1: 298.88, Y1: 622.5,  X2: 108.63, Y2: 597,    X3: 108.73, Y3: 406.94, X4: 99.6,   Y4: 401.58, },
{ SN: '12', BLK: 'ECP4',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: 'Access P/F (East-South)',    X1: 444.59, Y1: 570.93, X2: 486.02, Y2: 412.3,  X3: 486.02, Y3: 224.11, X4: 460.3,  Y4: 240.3, },
{ SN: '13', BLK: 'ECP5',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: 'Access P/F (East-South)',    X1: 434.09, Y1: 561.01, X2: 475.06, Y2: 402.38, X3: 475.06, Y3: 214.18, X4: 456.1,  Y4: 219.8, },
{ SN: '14', BLK: 'ECP6',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: 'Access P/F (East-South)',    X1: 433.44, Y1: 536.06, X2: 474.71, Y2: 377.43, X3: 474.35, Y3: 189.24, X4: 464.29, Y4: 182.46, },
{ SN: '15', BLK: 'ECP7',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: 'Access P/F (East-South)',    X1: 489.44, Y1: 542.05, X2: 530.33, Y2: 383.74, X3: 530.35, Y3: 195.23, X4: 542.85, Y4: 189.42, },
{ SN: '16', BLK: 'EF21',  AREA: 'Hull', ZONE: 'UCFE',  KEY: 'Y', TITLE: 'UCF East',                   X1: 346.11, Y1: 571.32, X2: 346.11, Y2: 571.32, X3: 277.43, Y3: 286.35, X4: 277.98, Y4: 286.93, },
{ SN: '17', BLK: 'EN112', AREA: 'Hull', ZONE: 'EN',    KEY: 'Y', TITLE: 'Node (East-North)',          X1: 298.11, Y1: 723.36, X2: 107.86, Y2: 697.59, X3: 107.86, Y3: 697.59, X4: 108.44, Y4: 698.17, },
{ SN: '18', BLK: 'EN161', AREA: 'Hull', ZONE: 'ENC',   KEY: 'Y', TITLE: 'Diagonal Node (East-North)', X1: 356.89, Y1: 725.46, X2: 166.64, Y2: 700.22, X3: 191.95, Y3: 700.22, X4: 192.53, Y4: 700.27, },
{ SN: '19', BLK: 'EN212', AREA: 'Hull', ZONE: 'ES',    KEY: 'Y', TITLE: 'Node (East-South)',          X1: 435.82, Y1: 643.71, X2: 477.23, Y2: 485.08, X3: 477.23, Y3: 485.08, X4: 477.28, Y4: 485.66, },
{ SN: '20', BLK: 'EN261', AREA: 'Hull', ZONE: 'ESC',   KEY: 'Y', TITLE: 'Diagonal Node (East-South)', X1: 439.36, Y1: 677.83, X2: 480.25, Y2: 519.22, X3: 480.25, Y3: 544.59, X4: 480.83, Y4: 545.16, },
{ SN: '21', BLK: 'EN41',  AREA: 'Hull', ZONE: 'EN',    KEY: '',  TITLE: 'Push Knee',                  X1: 304.9,  Y1: 714.43, X2: 114.65, Y2: 688.78, X3: 114.65, Y3: 688.78, X4: 99.38,  Y4: 680.47, },
{ SN: '22', BLK: 'EN42',  AREA: 'Hull', ZONE: 'EN',    KEY: '',  TITLE: 'Push Knee',                  X1: 305.48, Y1: 741.46, X2: 115.23, Y2: 716.2,  X3: 115.23, Y3: 716.2,  X4: 88.13,  Y4: 736.15, },
{ SN: '23', BLK: 'EN51',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: 'Push Knee',                  X1: 443.48, Y1: 626.4,  X2: 484.36, Y2: 467.95, X3: 484.36, Y3: 467.95, X4: 474.48, Y4: 460.86, },
{ SN: '24', BLK: 'EN52',  AREA: 'Hull', ZONE: 'ES',    KEY: '',  TITLE: 'Push Knee',                  X1: 486.64, Y1: 628.66, X2: 527.75, Y2: 470.03, X3: 527.75, Y3: 470.03, X4: 540.63, Y4: 463.3, },
{ SN: '25', BLK: 'EP112', AREA: 'Hull', ZONE: 'EAST',  KEY: 'Y', TITLE: 'Pontoon (East)',             X1: 330.78, Y1: 662.84, X2: 257.12, Y2: 569.8,  X3: 257.12, Y3: 569.8,  X4: 257.58, Y4: 570.38, },
{ SN: '26', BLK: 'NF21',  AREA: 'Hull', ZONE: 'UCFN',  KEY: 'Y', TITLE: 'UCF North',                  X1: 345.37, Y1: 649.53, X2: 345.37, Y2: 649.53, X3: 257,    Y3: 494.57, X4: 257.58, Y4: 494.93, },
{ SN: '27', BLK: 'NFP1',  AREA: 'Hull', ZONE: 'UCFN',  KEY: 'Y', TITLE: 'UCF P/F (North)',            X1: 381.61, Y1: 661.99, X2: 381.61, Y2: 661.99, X3: 293.41, Y3: 506.81, X4: 320.14, Y4: 491.13, },
{ SN: '28', BLK: 'NP112', AREA: 'Hull', ZONE: 'NORTH', KEY: 'Y', TITLE: 'Pontoon (North)',            X1: 331.02, Y1: 751.26, X2: 232.19, Y2: 777.62, X3: 232.19, Y3: 777.62, X4: 232.77, Y4: 778.2, },
{ SN: '29', BLK: 'NPB1',  AREA: 'Hull', ZONE: 'NORTH', KEY: '',  TITLE: '',                           X1: 379.67, Y1: 801,    X2: 281.35, Y2: 827.36, X3: 281.35, Y3: 827.36, X4: 254.23, Y4: 842.73, },
{ SN: '30', BLK: 'SF21',  AREA: 'Hull', ZONE: 'UCFS',  KEY: 'Y', TITLE: 'UCF South',                  X1: 488.71, Y1: 566.68, X2: 488.71, Y2: 566.68, X3: 642.64, Y3: 280.23, X4: 642.45, Y4: 280.81, },
{ SN: '31', BLK: 'SP112', AREA: 'Hull', ZONE: 'SOUTH', KEY: 'Y', TITLE: 'Pontoon (South)',            X1: 483.84, Y1: 662.84, X2: 620.82, Y2: 559,    X3: 620.82, Y3: 559,    X4: 621.28, Y4: 559.58, },
{ SN: '32', BLK: 'WC112', AREA: 'Hull', ZONE: 'WN',    KEY: 'Y', TITLE: 'Lower Column (West-North)',  X1: 433.21, Y1: 762.37, X2: 433.21, Y2: 845.84, X3: 433.52, Y3: 762.6,  X4: 433.79, Y4: 763.18, },
{ SN: '33', BLK: 'WC1G2', AREA: 'Hull', ZONE: 'WN',    KEY: 'Y', TITLE: 'Upper Column (West-North)',  X1: 440.95, Y1: 704.42, X2: 440.95, Y2: 787.84, X3: 440.98, Y3: 600.57, X4: 441.53, Y4: 601.16, },
{ SN: '34', BLK: 'WC1K3', AREA: 'Hull', ZONE: 'WN',    KEY: 'Y', TITLE: 'Middle Column (West-North)', X1: 437.45, Y1: 726.05, X2: 435.51, Y2: 809.35, X3: 435.4,  Y3: 673.44, X4: 436.18, Y4: 675.44, },
{ SN: '35', BLK: 'WC212', AREA: 'Hull', ZONE: 'WS',    KEY: 'Y', TITLE: 'Lower Column (West-South)',  X1: 582.65, Y1: 683.56, X2: 813.84, Y2: 633.82, X3: 813.84, Y3: 573.82, X4: 814.42, Y4: 573.96, },
{ SN: '36', BLK: 'WC2G2', AREA: 'Hull', ZONE: 'WS',    KEY: 'Y', TITLE: 'Upper Column (West-South)',  X1: 580.54, Y1: 622.64, X2: 812.16, Y2: 572.46, X3: 811.77, Y3: 384.78, X4: 812.31, Y4: 385.36, },
{ SN: '37', BLK: 'WC2K2', AREA: 'Hull', ZONE: 'WS',    KEY: 'Y', TITLE: 'Middle Column (West-South)', X1: 582.64, Y1: 642.94, X2: 813.83, Y2: 592.84, X3: 813.83, Y3: 469.22, X4: 814.41, Y4: 469.38, },
{ SN: '38', BLK: 'WC3A',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: '',                           X1: 493.3,  Y1: 787.8,  X2: 493.3,  Y2: 871.12, X3: 493.46, Y3: 789.42, X4: 523.3,  Y4: 805.88, },
{ SN: '39', BLK: 'WC4A',  AREA: 'Hull', ZONE: 'WS',    KEY: '',  TITLE: '',                           X1: 619.41, Y1: 718.54, X2: 850.6,  Y2: 668.36, X3: 850.6,  Y3: 608.36, X4: 883.62, Y4: 627.9, },
{ SN: '40', BLK: 'WCP1',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Access P/F (West-North)',    X1: 490.9,  Y1: 725.51, X2: 490.9,  Y2: 808.88, X3: 490.97, Y3: 621.67, X4: 509.18, Y4: 631.61, },
{ SN: '41', BLK: 'WCP2',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Access P/F (West-North)',    X1: 495.1,  Y1: 701.56, X2: 495.1,  Y2: 785.08, X3: 495.1,  Y3: 597.8,  X4: 513.16, Y4: 591.65, },
{ SN: '42', BLK: 'WCP3',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Access P/F (West-North)',    X1: 481.63, Y1: 693.57, X2: 481.63, Y2: 776.67, X3: 481.63, Y3: 590.07, X4: 493.58, Y4: 580.53, },
{ SN: '43', BLK: 'WCP4',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Access P/F (West-North)',    X1: 432.31, Y1: 697.36, X2: 432.31, Y2: 780.46, X3: 432.66, Y3: 593.52, X4: 415.1,  Y4: 579.1, },
{ SN: '44', BLK: 'WCP5',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Access P/F (West-North)',    X1: 422.41, Y1: 702.31, X2: 422.73, Y2: 785.41, X3: 422.41, Y3: 598.47, X4: 401.41, Y4: 592.05, },
{ SN: '45', BLK: 'WCP6',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Access P/F (West-North)',    X1: 438.03, Y1: 725.51, X2: 438.03, Y2: 808.89, X3: 438.09, Y3: 621.67, X4: 417.72, Y4: 634.44, },
{ SN: '46', BLK: 'WCP8',  AREA: 'Hull', ZONE: 'WS',    KEY: '',  TITLE: 'Access P/F (West-South)',    X1: 615.54, Y1: 645,    X2: 846.91, Y2: 594.83, X3: 846.91, Y3: 407.15, X4: 874.02, Y4: 423.08, },
{ SN: '47', BLK: 'WCP9',  AREA: 'Hull', ZONE: 'WS',    KEY: '',  TITLE: 'Access P/F (West-South)',    X1: 627.44, Y1: 622.08, X2: 858.65, Y2: 571.9,  X3: 858.65, Y3: 384.22, X4: 873.34, Y4: 377.9, },
{ SN: '48', BLK: 'WF21',  AREA: 'Hull', ZONE: 'UCFW',  KEY: 'Y', TITLE: 'UCF West',                   X1: 486.64, Y1: 652.74, X2: 486.64, Y2: 652.74, X3: 618.17, Y3: 474.52, X4: 617.99, Y4: 475.1, },
{ SN: '49', BLK: 'WN112', AREA: 'Hull', ZONE: 'WN',    KEY: 'Y', TITLE: 'Node (West-North)',          X1: 436.02, Y1: 808.86, X2: 436.11, Y2: 891.96, X3: 436.11, Y3: 891.96, X4: 436.6,  Y4: 892.54, },
{ SN: '50', BLK: 'WN161', AREA: 'Hull', ZONE: 'WNC',   KEY: 'Y', TITLE: 'Diagonal Node (West-North)', X1: 439.52, Y1: 799.78, X2: 440.19, Y2: 882.88, X3: 440.19, Y3: 856.96, X4: 440.1,  Y4: 857.54, },
{ SN: '51', BLK: 'WN212', AREA: 'Hull', ZONE: 'WS',    KEY: 'Y', TITLE: 'node (West-South)',          X1: 582.78, Y1: 724.15, X2: 813.98, Y2: 673.98, X3: 813.98, Y3: 673.98, X4: 814.55, Y4: 674.56, },
{ SN: '52', BLK: 'WN261', AREA: 'Hull', ZONE: 'WSC',   KEY: 'Y', TITLE: 'Diagonal Node (West-South)', X1: 568.18, Y1: 726.95, X2: 799.5,  Y2: 676.77, X3: 773.38, Y3: 676.77, X4: 773.83, Y4: 677.35, },
{ SN: '53', BLK: 'WN41',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Push Knee',                  X1: 488.29, Y1: 820.88, X2: 488.29, Y2: 903.98, X3: 488.29, Y3: 903.98, X4: 514.42, Y4: 919.47, },
{ SN: '54', BLK: 'WN42',  AREA: 'Hull', ZONE: 'WN',    KEY: '',  TITLE: 'Push Knee',                  X1: 440.31, Y1: 819.24, X2: 440.31, Y2: 902.51, X3: 440.31, Y3: 902.51, X4: 417.66, Y4: 916.38, },
{ SN: '55', BLK: 'WN51',  AREA: 'Hull', ZONE: 'WS',    KEY: '',  TITLE: 'Push Knee',                  X1: 624.76, Y1: 739.95, X2: 855.94, Y2: 690.27, X3: 855.94, Y3: 690.27, X4: 881.05, Y4: 705.01, },
{ SN: '56', BLK: 'WN52',  AREA: 'Hull', ZONE: 'WS',    KEY: '',  TITLE: 'Push Knee',                  X1: 628.02, Y1: 714.75, X2: 859.21, Y2: 664.57, X3: 859.21, Y3: 664.57, X4: 869.2,  Y4: 658.6, },
{ SN: '57', BLK: 'WP112', AREA: 'Hull', ZONE: 'WEST',  KEY: 'Y', TITLE: 'Pontoon (West)',             X1: 484.3,  Y1: 752.14, X2: 599.35, Y2: 768.28, X3: 599.35, Y3: 768.28, X4: 599.93, Y4: 768.86, },
{ SN: '58', BLK: 'WPB1',  AREA: 'Hull', ZONE: 'WEST',  KEY: '',  TITLE: 'Porch Basket',               X1: 523.55, Y1: 807.67, X2: 639.09, Y2: 823.8,  X3: 639.09, Y3: 823.8,  X4: 669.5,  Y4: 841.4, },
{ SN: '59', BLK: 'WPG1',  AREA: 'Hull', ZONE: 'WEST',  KEY: '',  TITLE: '',                           X1: 495.76, Y1: 791.57, X2: 610.81, Y2: 807.71, X3: 610.81, Y3: 807.71, X4: 609.84, Y4: 767.19, },

    ],

    SQL2: [
      { AREA: 'Topside', ZONE: 'North Module',                WT: 5305,  FAB: 99,  PE1: 7,  PE2: 6, PE3: 0, EREC: 1, },
      { AREA: 'Topside', ZONE: 'Centeral',                    WT: 6459,  FAB: 93,  PE1: 12, PE2: 7, PE3: 0, EREC: 1, },
      { AREA: 'Topside', ZONE: 'South Module',                WT: 5054,  FAB: 101, PE1: 10, PE2: 6, PE3: 0, EREC: 1, },
      { AREA: 'Topside', ZONE: 'Flare Tower',                 WT: 272,   FAB: 6,   PE1: 3,  PE2: 1, PE3: 0, EREC: 1, },
      { AREA: 'Topside', ZONE: 'LQ / Heli Deck',              WT: 1588,  FAB: 39,  PE1: 6,  PE2: 0, PE3: 0, EREC: 3, },
      { AREA: 'Hull',    ZONE: 'Node',                        WT: 4195,  FAB: 104, PE1: 8,  PE2: 4, PE3: 0, EREC: 8, },
      { AREA: 'Hull',    ZONE: 'Pontoon',                     WT: 5102,  FAB: 96,  PE1: 12, PE2: 4, PE3: 0, EREC: 7, },
      { AREA: 'Hull',    ZONE: 'Column',                      WT: 10158, FAB: 262, PE1: 16, PE2: 8, PE3: 4, EREC: 30, },
      { AREA: 'Hull',    ZONE: 'UCF',                         WT: 1410,  FAB: 13,  PE1: 0,  PE2: 0, PE3: 0, EREC: 6, },
      { AREA: 'Hull',    ZONE: 'Puch Knee',                   WT: 40,    FAB: 8,   PE1: 0,  PE2: 0, PE3: 0, EREC: 8, },
      { AREA: 'Hull',    ZONE: 'Caisson / Riser / Sump Pile', WT: 543,   FAB: 10,  PE1: 0,  PE2: 0, PE3: 0, EREC: 8, },
    ],

    SQL3: [
      { CODE: 'AREA',  NAME: 'Area', },
      { CODE: 'ZONE',  NAME: 'Zone', },
      { CODE: 'WT',    NAME: 'W/T(ton)', },
      { CODE: 'FAB',   NAME: 'Fab.', },
      { CODE: 'PE1',   NAME: '1st PE', },
      { CODE: 'PE2',   NAME: '2nd PE', },
      { CODE: 'PE3',   NAME: '3rd PE', },
      { CODE: 'EREC', NAME: 'Erection', },
    ],

    SQL4: [
      { AREA: 'Topside', STAGE: 'Steel Cutting',  TOTAL: 338, ACT: 0, REM: 338, },
      { AREA: 'Topside', STAGE: 'Fabrication',    TOTAL: 338, ACT: 0, REM: 338, },
      { AREA: 'Topside', STAGE: '1st P.E',        TOTAL: 38,  ACT: 0, REM: 38, },
      { AREA: 'Topside', STAGE: 'Block Painting', TOTAL: 0,   ACT: 0, REM: 0, },
      { AREA: 'Topside', STAGE: '2nd /3rd P.E',   TOTAL: 20,  ACT: 0, REM: 20, },
      { AREA: 'Topside', STAGE: 'Erection',       TOTAL: 7,   ACT: 0, REM: 7, },
      { AREA: 'Hull',    STAGE: 'Steel Cutting',  TOTAL: 493, ACT: 0, REM: 493, },
      { AREA: 'Hull',    STAGE: 'Fabrication',    TOTAL: 493, ACT: 0, REM: 493, },
      { AREA: 'Hull',    STAGE: '1st P.E',        TOTAL: 36,  ACT: 0, REM: 36, },
      { AREA: 'Hull',    STAGE: 'Block Painting', TOTAL: 0,   ACT: 0, REM: 0, },
      { AREA: 'Hull',    STAGE: '2nd /3rd P.E',   TOTAL: 20,  ACT: 0, REM: 20, },
      { AREA: 'Hull',    STAGE: 'Erection',       TOTAL: 67,  ACT: 0, REM: 67, },
      { AREA: 'Overall', STAGE: 'Steel Cutting',  TOTAL: 831, ACT: 0, REM: 831, },
      { AREA: 'Overall', STAGE: 'Fabrication',    TOTAL: 831, ACT: 0, REM: 831, },
      { AREA: 'Overall', STAGE: '1st P.E',        TOTAL: 74,  ACT: 0, REM: 74, },
      { AREA: 'Overall', STAGE: 'Block Painting', TOTAL: 0,   ACT: 0, REM: 0, },
      { AREA: 'Overall', STAGE: '2nd /3rd P.E',   TOTAL: 40,  ACT: 0, REM: 40, },
      { AREA: 'Overall', STAGE: 'Erection',       TOTAL: 74,  ACT: 0, REM: 74, },
    ],

  },
  
}