/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/

// Special Charts
import TR_SCURVE_ENG_Data             from '../10_Trion/Charts/SCurve_ENG/includes/primitives/data'
import TR_SCURVE_PRO_Data             from '../10_Trion/Charts/SCurve_PRO/includes/primitives/data'
import TR_SCURVE_CON_Data             from '../10_Trion/Charts/SCurve_CON/includes/primitives/data'
import TR_CONST_AREA_Data             from '../10_Trion/Charts/Const_Area/includes/primitives/data'
import TR_CONST_STAGE_Data            from '../10_Trion/Charts/Const_Stages/includes/primitives/data'

// Skyline Charts
import TR_SKYLINE_BLOCK_Data          from '../10_Trion/Skylines/Blocks/includes/primitives/data'

// 3D Visualization
import TR_Erection_Network_Data       from '../10_Trion/Visualization/Erection_Network/includes/primitives/data'
import TR_Erection_Timeline_Data      from '../10_Trion/Visualization/Erection_Timeline/includes/primitives/data'
import TR_Block_Division_Data         from '../10_Trion/Visualization/Block_Division/includes/primitives/data'
import TR_Layout_Equipment_Data       from '../10_Trion/Visualization/Layout_Equipment/includes/primitives/data'
import TR_Layout_Yard_Data            from '../10_Trion/Visualization/Layout_Yard/includes/primitives/data'

export default {

  // Special Charts
  TR_SCURVE_ENG_Data,
  TR_SCURVE_PRO_Data,
  TR_SCURVE_CON_Data,
  TR_CONST_AREA_Data,
  TR_CONST_STAGE_Data,

  // Skyline Charts
  TR_SKYLINE_BLOCK_Data,

  // 3D Visualization
  TR_Erection_Network_Data,
  TR_Erection_Timeline_Data,
  TR_Block_Division_Data,
  TR_Layout_Equipment_Data,
  TR_Layout_Yard_Data,

}
